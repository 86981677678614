import React from "react"
import { Link } from "gatsby"
import { css, Styled } from "theme-ui"

import WebbleLogo from "../../../content/assets/logo-square.svg"

const rootPath = `${__PATH_PREFIX__}/`

const Title = ({location}) => {
  if (location.pathname === rootPath) {
    return <Styled.h1>webble blog</Styled.h1>
  } else {
    return (
      <Styled.a as={Link} css={css({
        textDecoration: `none`,
        color: `inherit`
      })} to={`/`}>
        <Styled.h1>webble blog</Styled.h1>
      </Styled.a>
    )
  }
}

const Header = ({ children, title, ...props }) => (
  <header>
    <div
      css={css({
        maxWidth: `container`,
        mx: `auto`,
        px: 3,
        pt: 4,
      })}
    >
      <div
        css={css({
          display: `flex`,
          justifyContent: `flex-start`,
          alignItems: `bottom`,
          mb: 4,
        })}
      >
        <img src={WebbleLogo} alt="Webble Logo" css={css({
          height: "4rem",
          marginRight: "1ch"
          })} />
        <Title {...props} />
        {children}
      </div>
    </div>
  </header>
)

export default Header
